

import config from './config'
import loadWebFonts from './webfonts'
import classes from 'dom-classes'
import {on, off} from 'dom-events'
import {TweenLite, TimelineMax} from 'gsap'
import Swiper from 'swiper'
import AOS from 'aos' 

const myApp = {

  init: function() {

    console.log('init mama')

    if(config.isXS) config.swiper = true

    loadWebFonts()
    this.bindElements()
    this.addEvents()
    this.animateIn()

    
  },

  bindElements: function() {

    this.cards = Array.from(document.querySelectorAll('.js-open'))
    this.hovers = Array.from(document.querySelectorAll('.js-hover'))
    this.bg2 = document.querySelector('.s-bg[data-img="2"]').style.backgroundImage
    this.bg4 = document.querySelector('.s-bg[data-img="2"]').style.backgroundImage
    this.burger = document.querySelector('.burger')
    this.menu = document.querySelector('.menu')
    this.a = Array.from(document.querySelectorAll('.menu a'))
  
    window.addEventListener('resize', (e)=> {
      this.resize = this.resize.bind(this)
      this.resize()
    })

    this.imgs = []

	},

	addEvents: function() {
    
    if(config.isXS || config.isS) this.cards.forEach( (card)=>  on(card, 'click', this.toogleCard))
    if(config.isXS && config.swiper) this.addSwiper()
    if(config.isL) this.hovers.forEach( (el)=>  on(el, 'mouseenter', this.changeImg.bind(this)))
    if(config.isL) this.hovers.forEach( (el)=>  on(el, 'mouseleave', this.orginalImg.bind(this)))
    
    on(this.burger, 'click', this.openMenu.bind(this))
    this.a.forEach( (link)=>   on(link, 'click', this.closeMenu.bind(this))  )
    
    AOS.init();

  },

  openMenu: function(e) {
    TweenLite.set(this.menu, {display: 'block'})
    TweenLite.fromTo(this.menu, 0.5, {autoAlpha: 0}, {autoAlpha: 1})
    TweenLite.to(this.burger, {autoAlpha: 0})
  },

  closeMenu: function(e) {

    const id = e.target.id
    
    TweenLite.to(this.menu, 0.5, {autoAlpha: 0, delay: 0.5, onComplete: ()=> {
      TweenLite.set(this.menu, {display: 'none'})
      TweenLite.to(this.burger, {autoAlpha: 1})
    }})

    //TweenLite.to(window, 2, {scrollTo: id});

  },

  changeImg: function(e) {
    
    const id = e.target.dataset.id
    const x = e.target.dataset.x
    const selector = `#${x+'-bg-wrap'}`
    const bg = document.querySelector(`${selector} .s-bg[data-id='${id}']`)
    const bgs = Array.from(document.querySelectorAll(selector + ' ' +'.s-bg-hover'))
    
    TweenLite.to(bgs, 0.3, {autoAlpha: 0, onComplete: ()=> {
      TweenLite.to(bg, 0.3, {autoAlpha: 1})
    }})
    
  },

  orginalImg: function(e) {

    const x = e.target.dataset.x
    const selector = `#${x+'-bg-wrap'}`
    const bg = document.querySelector(`${selector} .s-bg[data-id='0']`)
    const bgs = Array.from(document.querySelectorAll(selector + ' ' +'.s-bg-hover'))

    TweenLite.to(bgs, 0.3, {autoAlpha: 0, onComplete: ()=> {
      TweenLite.to(bg, 0.3, {autoAlpha: 1})
    }})

    
  },

  removeEvents: function() {

    if(!config.isXS && !config.isS) this.cards.forEach( (card)=>  off(card, 'click', this.toogleCard))
    if(!config.isXS ) {

     if( this.s1) this.s1.destroy()
     if( this.s2) this.s2.destroy()
     if( this.s3) this.s3.destroy()
     if( this.s4) this.s4.destroy()

      config.swiper = true
     
    }
  },
  
  toogleCard: function() {

    classes.toggle(this, 'active')
  },

  addSwiper: function() {

    config.swiper = false

    this.s1 = new Swiper ('#s1-cards-container', {
      
      pagination: {
        el: '.s1-nav',
        clickable: true,
    },
    })
    this.s2 = new Swiper ('#s2-cards-container', {
     
      pagination: {
        el: '.s2-nav',
        clickable: true,
    },
    })
    this.s3 = new Swiper ('#s3-cards-container', {
     
      pagination: {
        el: '.s3-nav',
        clickable: true,
    },
    })
    this.s4 = new Swiper ('#s4-cards-container', {
      
      pagination: {
        el: '.s4-nav',
        clickable: true,
    },
    })

  },

  resize: function() {

    config.width = window.innerWidth
    config.isXS = window.innerWidth <= 500
    config.isS = window.innerWidth >= 501 && window.innerWidth <= 719
    config.isM = window.innerWidth >= 720 && window.innerWidth <= 1023
    config.isL = window.innerWidth >= 1024 

    this.addEvents()
    this.removeEvents()

  },

  animateIn: function() {


    const tl =  new TimelineMax({ paused: true, onComplete: () => {

    }})

    tl.from('.preloader', 0.7, {y: '100%', delay: 1, onComplete: ()=>  {
      classes.add(document.querySelector('.sprite'), 'animate')
      classes.remove(document.querySelector('.is-hidden'), 'is-hidden')
    }, ease: Power3.easeOut})
    tl.to('.preloader', 0.7, {height: '0%', delay: 2.4, ease: Power3.easeOut})
    tl.from('.header-bg', 1, {scale: 1.2}, '-=0.8')
    tl.from('#logo',1.2, {autoAlpha: 0, x: 10, ease: Power3.easeOut}, '-=0.8')
    tl.from('#header-content',0.8, {autoAlpha: 0, y: 10, ease: Power3.easeOut}, '-=0.8')

    tl.restart()

  }

}

myApp.init();